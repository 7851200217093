import React, { useState, useEffect } from "react"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"
import { Link } from "gatsby"
import TitleUnderline from "./titleUnderline"

const ComparePrice = ({ data }) => {
  //funcion para slider de logos clientes
  var settings = {
    slidesToShow: 1,
    infinite: false,
    initialSlide: 0,
    dots: true,
    speed: 500,
    arrows: true,
  }

  const features = [
    "Características generales",
    "Mínimo de usuarios mensuales",
    "Canales disponibles",
    "Creación de empresas, campañas y bases",
    "Creación de tipificaciones",
    "Creación de formularios",
    "Creación de colas y skills",
    "Supervisión en tiempo real de agentes",
    "Supervisión en tiempo real de llamadas",
    "Monitoreo online via remote desktop",
    "Dashboards y reportes",
    "Etiquetas y campos customizados",
    "Workflows (Secuencias)",
    "Beex AI",
    "Consumo de Beex API",
    "Personalizaciones en Beex API",
    "Tickets al mes",
    "Contactos al mes",
    "Personalizaciones y requerimientos",
    "Tiempo de almacenamiento de data",
    "Soporte por",
    "Tiempo de compromiso mínimo a contratar",
    "Telefonía",
    "Llamadas al mes",
    "Llamadas entrantes",
    "Números entrantes (DIDs)",
    "Llamadas salientes",
    "IVR entrante",
    "IVR saliente con retorno",
    "Encuestas de satisfacción via IVR",
    "Marcación telefónica preview",
    "Marcación telefónica progresiva",
    "# Progresivos lanzados concurrentes",
    "Marcación telefónica predictiva",
    "# Predictivos lanzados concurrentes",
    "Grabación de llamadas",
    "Máscara de números salientes personalizables",
    "Mensajería",
    "Conversaciones al mes",
    "Chatbots",
    "Widget webchat",
    "Solicitud de plantillas de WhatsApp",
    "Campañas masivas de WhatsApp",
  ]

  const [windowsWidth, setWindowsWidth] = useState(true)
  useEffect(() => {
    let windowWidth = window.screen.width
    if (windowWidth < 1000) {
      setWindowsWidth(false)
    } else {
      setWindowsWidth(true)
    }
  }, [])

  const [fixedCabecera, setFixedCabecera] = useState(false)

  useEffect(() => {
    window.onscroll = function () {
      if (window.scrollY >= 4050) {
        setFixedCabecera(false)
      } else if (window.scrollY >= 1200) {
        setFixedCabecera(true)
      } else {
        setFixedCabecera(false)
      }
    }
  }, [setFixedCabecera])

  return (
    <section className=" container-pages-price-score container-price-ticker container-price-score">

      <p className="container-price-score-title">
        <TitleUnderline underline="Precios" /> de Beex Contact Center
      </p>

      {windowsWidth ? (
        <div className="container-boxprice-ticker">
          <div
            className={`${fixedCabecera ? "container-boxprice-score-true container-boxprice-ticker-true " : " "
              }`}
          >
            <div className="cabecera-price-ticker">
              <h2 style={{ color: "transparent" }}> Caracteristicas</h2>
              <div className="cabecera-price-ticker-planes">
                {data.map((elemento, i) => {
                  return (
                    <div
                      key={i}
                      className="container-price-ticker__containerfeatures__featureslogo"
                    >
                      <img
                        src={elemento.img}
                        alt="..."
                        className={elemento.classlogo}
                      />
                      <p className="container-price-ticker__containerfeatures__featurestitle">
                        {elemento.title}
                      </p>
                      {elemento.price}
                      <Link
                        href={elemento.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="btn-slider-price-white"
                      >
                        {elemento.boton}
                      </Link>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
          <div className={`${fixedCabecera ? "boxprice-ticker-true" : " "}`} />

          <section className="container-price-ticker__containerfeatures">
            {caracteristica(features, windowsWidth)}
            <div className="container-price-ticker__containerfeatures__features">
              {data.map(elemento => {
                return (
                  <div
                    className={`container-price-ticker__containerfeatures__features-detail`}
                    key={elemento}
                  >
                    {Detail(elemento, windowsWidth)}
                  </div>
                )
              })}
            </div>
          </section>
        </div>
      ) : (
        <Slider {...settings}>
          {data.map(elemento => {
            return (
              <>
                {windowsWidth ? (
                  ""
                ) : (
                  <div className="container-price-ticker__containerfeatures__featureslogo">
                    <img
                      src={elemento.img}
                      alt="..."
                      className={elemento.classlogo}
                    />
                    <p className="container-price-ticker__containerfeatures__featurestitle">
                      {elemento.title}
                    </p>
                    {elemento.price}
                    <Link
                      href={elemento.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="btn-slider-price-white"
                    >
                      {elemento.boton}
                    </Link>
                  </div>
                )}

                <section
                  className={`container-price-ticker__containerfeatures `}
                  key={elemento}
                >
                  {caracteristica(features)}
                  <div className="container-price-ticker__containerfeatures__features">
                    <div
                      className={`container-price-ticker__containerfeatures__features-detail`}
                    >
                      {Detail(elemento, windowsWidth)}
                    </div>
                  </div>
                </section>
              </>
            )
          })}
        </Slider>
      )}
    </section>
  )
}
export default ComparePrice

const Detail = elemento => {
  return (
    <div>
      {elemento.info.map((item, index) => {
        return (
          <div
            className={`container-price-ticker__containerfeatures__features-detail--info ${index === 0 || index === 20 || index === 35
                ? "boton-open-description-pricing"
                : index % 2 === 0
                  ? "container-price-ticker__item-blue"
                  : ""
              } `}
            key={index}
          >
            {item}
          </div>
        )
      })}
    </div>
  )
}

const caracteristica = features => {
  return (
    <div className="container-title-caracteristic-price-score">
      {features.map((item, index) => (
        <div
          className={`container-price-ticker__item ${index === 0 || index === 20 || index === 35
              ? "boton-open-description-pricing"
              : index % 2 === 0
                ? "container-price-ticker__item-blue"
                : "blanco"
            } `}
          key={index}
        >
          <p
            className={
              index === 0 || index === 20 || index === 35
                ? "title-open-description-pricing"
                : ""
            }
          >
            {item}
          </p>
        </div>
      ))}
    </div>
  )
}
